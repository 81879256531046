window.clearGarlic = class clearGarlic {
  destroy() {
    var aGarlicKeys = [];
    for ( var i = 0, len = localStorage.length; i < len; ++i ) {
      if ( localStorage.key(i).indexOf("garlic") >= 0 ) {
        aGarlicKeys.push( localStorage.key(i) );
      }
    };

    jQuery.each(aGarlicKeys, function(index, item) {
      localStorage.removeItem(item);
    });
  }
}
