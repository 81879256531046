// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';

window.$ = window.JQuery = window.jQuery = JQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("garlicjs")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/alert';
import Inputmask from "inputmask";

import { Application } from '@hotwired/stimulus'
import StimulusControllerResolver from 'javascripts/stimulus-controller-resolver'

const application = Application.start()

StimulusControllerResolver.install(application, async controllerName => (
  (await import(`../controllers/${controllerName}_controller.js`)).default
))


Date.prototype.formatMMDDYYYY = function(){
  return (this.getMonth() + 1) +
  "/" +  this.getDate() +
  "/" +  this.getFullYear();
}



import clearGarlic from "../utils/clear_garlic"
document.addEventListener("turbolinks:load", function() {

  (document.querySelectorAll("[data-toggle='offcanvas']") || []).forEach((e) => {
    e.addEventListener("click", (e) => {
      let offCanvasCollapse = document.querySelector(".offcanvas-collapse");
      let togglerIcon = document.querySelector(".navbar-toggler-icon");

      offCanvasCollapse.classList.toggle('open');
      togglerIcon.classList.toggle('open');
    })
  });


  // Initialize popover
  $(function () {
    $('#deliver-to-wrapper').popover({ trigger: "manual" })
    $("[data-button='increase-quantity']").popover(
      {
        trigger: "manual",
        template: '<div class="popover is-warning" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
      }
    )

    $("[data-checkout-wrapper]").popover(
      {
        trigger: "manual",
        template: '<div class="popover is-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
      }
    )

  })
  // Initialize popover - END

  $('.category__filters .custom-checkbox').on('click', function(e){
    e.stopPropagation();
  });

  $(window).on('resize', function(){
    $('.dropdown-toggle.all-filters').dropdown('hide');
  })


  setTimeout(() => {
    (document.querySelectorAll(".alert:not(.alert-dismissible)") || []).forEach((e) => {
      $(e).fadeOut()
    })
  }, 10000)

  // Call garlic on turbolinks load
  $('form[data-persist="garlic"]').garlic();

  window.show_flash_message = (message_type, message) => {
    let flashWrapper = document.querySelector("[data-error-alert]");
    let alertMessage = flashWrapper.querySelector("[data-alert-message]")
    alertMessage.innerText = message;
    $(flashWrapper).fadeIn()
    flashWrapper.classList.remove("d-none");

    setTimeout(() => {
      $(flashWrapper).fadeOut()
    }, 10000)
  }


if ("loading" in HTMLImageElement.prototype) {
  var lazyEls = document.querySelectorAll("[loading=lazy]");
  lazyEls.forEach(function (lazyEl) {
    var source = lazyEl.closest('picture').querySelector('source');
    lazyEl.setAttribute("src", lazyEl.getAttribute("data-src"));
    source.setAttribute("srcset", source.getAttribute("data-srcset"));
  });
} else {
  let script = document.createElement("script");
  script.async = true;
  script.src =
    "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/4.1.8/lazysizes.min.js";
  document.body.appendChild(script);
}

  // ...
})